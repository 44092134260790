.aura-bg {
    background: linear-gradient(215deg, #000e3c, #32988a, #42985f);
    background-size: 600% 600%;
    // border:20px solid #750082;
    width: 100%;
    height: 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed;
    -webkit-animation: background-aura 44s ease infinite;
    -moz-animation: background-aura 44s ease infinite;
    -o-animation: background-aura 44s ease infinite;
    animation: background-aura 44s ease infinite;
    
}

.aura-content {
    text-align: center;
    padding: 2em;
    position: absolute;
    margin-top: 5rem;
    left: 50%; 
    transform: translateX(-50%);
    width: 80%;
//    padding-top: 30%;
    // position: absolute;

}
@-webkit-keyframes background-aura {
    0%{background-position:92% 0%}
    50%{background-position:9% 100%}
    100%{background-position:100% 0%}
}
@-moz-keyframes background-aura {
    0%{background-position:92% 0%}
    50%{background-position:9% 100%}
    100%{background-position:100% 0%}
}
@-o-keyframes background-aura {
    0%{background-position:92% 0%}
    50%{background-position:9% 100%}
    100%{background-position:100% 0%}
}
@keyframes background-aura {
    0%{background-position:92% 0%}
    50%{background-position:9% 100%}
    100%{background-position:92% 0%}
}